const domain = 'https://bydservices.pt/glintt-api/public/api/';

export default {
    async call_api_get (method) {
        let url = domain + method

        try {
            var response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            var result = await response.json()
            return result
        }
        catch (error) {
            console.error('Error:', error)
            return 'Error: ' + error
        }
    },
    async call_api_post (method, data) {
        let url = domain + method

        try {
            var response = await fetch(url, {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            var result = await response.json()
            return result
        }
        catch (error) {
            console.error('Error:', error)
            return 'Error: ' + error
        }
    },
}