import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: () => import('../views/Welcome.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('../views/Intro.vue')
  },
  {
    path: '/area-privada',
    name: 'AreaPrivada',
    component: () => import('../views/area-privada.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/destaques',
    name: 'destaques',
    component: () => import('../views/Destaques.vue')
  },
  {
    path: '/formulario/:id',
    name: 'formulario',
    component: () => import('../views/Formulario.vue')
  },
  {
    path: '/area/:id',
    name: 'Area',
    component: () => import('../views/Areas.vue')
  },
  {
    path: '/categoria/:id',
    name: 'categoria',
    component: () => import('../views/Categoria.vue')
  },
  {
    path: '/familia/:id',
    name: 'familia',
    component: () => import('../views/Familia.vue')
  },
  {
    path: '/produto/:id',
    name: 'produto',
    component: () => import('../views/Produto.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/novidades',
    name: 'novidades',
    component: () => import('../views/Novidades.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import ('../views/Cart.vue')
},
  // {
  //   path: '/assets',
  //   name: 'assets'
  // },
  // {
  //   path: '*',
  //   name: 'not-found',
  //   component: () => import('../views/Welcome.vue')
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
      console.log(reject)
    })
  }
})

// router.beforeEach((to, from, next) => {
//   let userId = window.sessionStorage.getItem('user_id');

//   let paths = ['/','/login','/reset-password','/novidades'];

//   if (!paths.includes(to.path) && userId == null) {
//     sessionStorage.setItem('redirectPath', to.path);
//     next({ name: 'login' });
//   } else {
//     console.log("session is valid");
//     next();
//   }
// })

export default router
