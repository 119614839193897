<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div class="container-fluid bannerCookies" v-if="showBanner">
      <div class="col-8">
        <p>
          A <b>GLINTT</b> - BUSINESS SOLUTIONS, LDA. irá instalar cookies que
          são necessários ao funcionamento deste website e pretende instalar
          cookies que permitem efetuar a medição estatística (agregada e
          anónima) da utilização do PORTAL GLINTT (ex., número de visitantes
          únicos) de forma a melhorar o PORTAL GLINTT. Estes cookies só serão
          instalados caso assim autorize. Uma opção padrão de "não autorizado"
          aplica-se no caso de não ser feita qualquer escolha e uma recusa não
          limitará a sua experiência de utilizador. Esta ferramenta irá ainda
          instalar um cookie no seu equipamento que guarda as suas preferências
          durante 90 dias. Ajuste as
          <a class="pointer" @click="setCookies()"> configurações AQUI.</a>
        </p>
      </div>
      <div class="col-4" style="text-align: right; padding-left: 50px">
        <div class="col aceitarBanner">
          <a
            target="_blank"
            class="button ghost diagonal pointer"
            @click="acceptCookies()"
          >
            Aceitar
          </a>
        </div>
        <div class="col naoAceitarbanner">
          <a
            target="_blank"
            class="button ghost diagonal pointer"
            @click="refuseCookies()"
          >
            Não Aceitar
          </a>
        </div>
      </div>
    </div>
    <div v-if="modal_cookies">
      <div id="popup">
        <button id="show-btn" class="buttonpop"></button>
        <div class="modal-overlay"></div>
        <transition name="slide" appear>
          <div class="modal modalCookies">
            <div style="padding-bottom: 50px"></div>
            <div class="container">
              <span @click="fecharModal()" class="w3-button w3-display-topright"
                >&times;</span
              >
              <div class="col-4">
                <div class="tabs_container">
                  <div
                    v-for="(tab, i) in tabs"
                    :key="i"
                    @click="selectedTab = tab.title"
                    :class="{ active: selectedTab == tab.title }"
                    class="tab-item"
                  >
                    <h3 class="tab-item__heading">{{ tab.title }}</h3>

                    <p class="tab-item__subheading">{{ tab.subtitle }}</p>
                  </div>
                </div>
              </div>
              <div class="col-8" style="overflow-y: scroll; height: 40vh">
                <template v-if="selectedTab == 'Política de Cookies'">
                  <ol id="listaCookies" style="list-style-type: decimal">
                    <li>
                      Cookies são
                      <b
                        >pequenos arquivos que recolhem informações e são
                        guardados no seu equipamento</b
                      >
                      através do seu navegador.
                    </li>
                    <li>
                      Podem ser
                      <b>estritamente necessários para o funcionamento</b>
                      do PORTAL GLINTT, por exemplo, para:
                      <ul style="padding-left: 20px; list-style-type: none">
                        <li>/identificar e autenticar o utilizador; ou</li>

                        <li>
                          /memorizar as preferências habituais do utilizador; ou
                        </li>
                        <li>
                          /permitir que o utilizador conclua tarefas sem ter de
                          voltar a inserir informações (por exemplo, quando muda
                          de página ou regresse, como no caso de dados de
                          formulário, cesto de compras, etc.); ou
                        </li>
                        <li>
                          /efetuar, permitir, facilitar ou suportar as
                          comunicações do/através do PORTAL GLINTT; ou
                        </li>
                        <li>
                          /fornecer algumas funcionalidades que o utilizador
                          pediu; ou
                        </li>
                        <li>
                          /segurança dos sistemas de informação e/ou prevenção
                          de fraudes.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Estes cookies irão ser
                      <b>armazenados no seu equipamento</b> e
                      <strong style="color: #3cb995"
                        >não precisam de consentimento</strong
                      >.
                    </li>
                    <li>
                      Os cookies podem igualmente ser utilizados para
                      <b>recolher informações</b>, por exemplo, para:
                    </li>
                    <ul style="padding-left: 20px; list-style-type: none">
                      <li>
                        /medir e obter estatísticas da utilização do PORTAL
                        GLINTT, por exemplo, número de visitantes únicos,
                        detetar as palavras-chave dos motores de pesquisa,
                        detetar aspetos da navegação como a duração da visita,
                        páginas mais visitadas ou conteúdos mais acedidos
                        (cookies de analítica); ou
                      </li>
                      <li>
                        /adaptar a publicidade e marketing com base nos seus
                        comportamentos e perfil, adaptando os anúncios a
                        apresentar (cookies de seguimento, publicidade,
                        analítica); ou
                      </li>
                      <li>
                        /terceiros, que não a GLINTT - BUSINESS SOLUTIONS, LDA.,
                        os quais recolhem as suas informações de navegação
                        através de diferentes sítios web de modo a criar os
                        referidos perfis (cookies de terceiros).
                      </li>
                    </ul>
                    <li>
                      O <b>armazenamento destes cookies</b> de seguimento,
                      publicidade, analítica e de terceiros
                      <strong style="color: #3cb995"
                        >dependerá sempre da sua aceitação e
                        consentimento</strong
                      >
                      prévio, podendo esse consentimento ser retirado em
                      qualquer momento através de ferramentas específicas e/ou
                      do seu navegador.
                    </li>
                    <li>
                      De forma semelhante aos cookies, os pixels de
                      rastreamento, web beacons e outras ferramentas semelhantes
                      são imagens e/ou pedaços de código carregados quando o
                      utilizador visita um website, uma página ou um conteúdo,
                      mas também, quando abre um email.
                    </li>
                    <li>
                      Essas ferramentas contêm uma ligação externa para um
                      servidor da ferramenta e permite
                      <b>rastrear o comportamento do utilizador</b>, para obter
                      dados para marketing online, análises, mas também, o
                      marketing de redireccionamento.
                    </li>
                    <li>
                      Quando o <b>utilizador visita o website</b> de destino
                      esta operação é <b>registada e anotada</b> nos arquivos de
                      registo (log) do servidor da ferramenta, podendo incluir
                      várias informações sobre o utilizador, nomeadamente, o
                      sistema operativo, duração da, ou, o endereço IP.
                    </li>
                    <li>
                      <b>O armazenamento destas ferramentas</b>
                      <strong style="color: #3cb995">
                        dependerá sempre da sua aceitação e consentimento
                        prévio</strong
                      >, podendo esse consentimento ser retirado em qualquer
                      momento através de ferramentas específicas e/ou do seu
                      navegador.
                    </li>
                  </ol>
                </template>
                <template
                  v-if="selectedTab == 'Cookies estritamente necessários'"
                >
                  O <b>PORTAL GLINTT</b> utiliza os seguintes cookies que são
                  estritamente necessários para o seu funcionamento e<strong
                    style="color: #3cb995"
                  >
                    não precisam de consentimento</strong
                  >:
                  <p class="mt-3">
                    <strong>Nome:</strong>
                  </p>
                  <p class="mt-3"><strong>Origem:</strong></p>
                  <p class="mt-3"><strong>Duração:</strong></p>
                  <p class="mt-3">
                    <strong>Finalidade:</strong>
                  </p>
                </template>
                <template
                  v-if="selectedTab == 'Cookies de analítica e terceiros'"
                >
                  <p class="mt-3">
                    O PORTAL GLINTT poderá utilizar os seguintes cookies
                    destinados ao seguimento, publicidade, analítica e também de
                    terceiros, os quais
                    <strong
                      >dependerão sempre da sua aceitação e consentimento
                      prévio</strong
                    >:
                  </p>
                  <p class="mt-3"><strong>Nome: </strong> Google Analytics</p>
                  <p class="mt-3">
                    <strong>Origem: </strong
                    ><span style="color: #333333; background: white"
                      >Google
                    </span>
                  </p>
                  <p class="mt-3"><strong>Duração:</strong> 24 h - 2 anos</p>
                  <p class="mt-3">
                    <strong>Finalidade:</strong> Armazenar e contar
                    visualizações de páginas para efeitos estatísticos. Cookie
                    que detém o Google Analytics ID, injectado através do Google
                    Tag Manager.
                  </p>
                  <div class="pretty p-switch">
                    <input type="checkbox" v-model="ga" />
                    <div class="state">
                      <label>{{ activeText("gid") }}</label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="button-wrapper text-right">
              <!-- <a
                id="submitCookies"
                class="button ghost diagonal pointer"
                @click="saveCookies"
              >
                Guardar
              </a> -->
              <button
                id="submitCookies"
                @click="saveCookies"
                class="button diagonal font-weight-500 text-uppercase pointer"
                style="color: #fff !important; text-transform: capitalize"
              >
                Guardar
              </button>
            </div>
            <!-- <div class="button-wrapper text-right">
              <button
                id="closeModal"
                @click="fecharModal()"
                class="button diagonal font-weight-500 text-uppercase pointer"
                style="color: #fff !important"
              >
                Fechar
              </button>
            </div> -->
          </div>
        </transition>
      </div>
    </div>
    <!-- <transition name="fade" mode="out-in"> -->
    <router-view :backdoor="n" @update-cart="updateCart" />
    <!-- </transition> -->
    <div id="alert-browser">
      <p>
        A nossa plataforma funciona melhor no <b>Google Chrome</b> e no
        <b>Mozilla Firefox</b>.
        <br />
        Experimente!
      </p>
      <span onclick="this.parentNode.style.display='none'">Entendi</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "app",
    data() {
      return {
        showBanner: true,
        n: 0,
        ga: false,
        gat: false,
        gid: false,
        modal_cookies: false,
        tabs: [
          {
            title: "Política de Cookies",
            subtitle: "",
          },
          {
            title: "Cookies estritamente necessários",
            subtitle: "",
          },
          {
            title: "Cookies de analítica e terceiros",
            subtitle: "",
          },
        ],
        selectedTab: "Política de Cookies",
      };
    },
    computed: {
      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      cartItems() {
        this.n;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
    },
    methods: {
      fecharModal() {
        this.modal_cookies = false;
        this.showBanner = true;
      },
      // deleteAllCookies() {
      //   const cookies = document.cookie.split(";");
      //   for (const cookie of cookies) {
      //     const eqPos = cookie.indexOf("=");
      //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      //   }
      //   if (
      //     localStorage.getItem("cookie1") != null ||
      //     localStorage.getItem("cookie2") != null ||
      //     localStorage.getItem("cookie3") != null
      //   ) {
      //     this.setCookiesLS();
      //   }
      // },

      refuseCookies() {
        localStorage.setItem("refuse", "1");
        this.showBanner = false;
      },
      setAnalitics() {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-KDHNMJ2");
      },
      saveCookies() {
        if (this.ga) {
          this.setAnalitics();
          localStorage.setItem("aceitouCookies", "1");
          this.showBanner = false;
          this.modal_cookies = false;
        } else {
          this.showBanner = true;
          this.modal_cookies = false;
        }
      },
      acceptCookies() {
        this.setAnalitics();
        localStorage.setItem("aceitouCookies", "1");
        this.showBanner = false;
        this.modal_cookies = false;
      },
      setCookiesLS() {
        console.log(this.$cookies, "cookies");
        if (localStorage.getItem("cookie1") != null) {
          this.$cookies.set("_ga", "GA1.1.1656488571.1639060501", "90d");
        }
        if (localStorage.getItem("cookie2") != null) {
          this.$cookies.set("_gat_UA-151608927-1", "1", "1d");
        }
        if (localStorage.getItem("cookie3") != null) {
          this.$cookies.set("_gid", "GA1.1.1104182923.1639060501", "1d");
        }
      },
      activeText(cookie) {
        if (cookie == "gid") {
          if (this.gid == false) {
            return "Desativado";
          } else {
            return "Ativado";
          }
        }
        if (cookie == "ga") {
          if (this.ga == false) {
            return "Desativado";
          } else {
            return "Ativado";
          }
        }
        if (cookie == "gat") {
          if (this.gat == false) {
            return "Desativado";
          } else {
            return "Ativado";
          }
        }
      },
      selectTab() {
        this.selectedTab = this.tab.title;
      },
      setCookies() {
        this.modal_cookies = true;
        this.showBanner = false;
      },
      async updateCart() {
        this.n += 1;
      },
      checkCookies() {
        if (
          localStorage.getItem("refuse") == "1" ||
          localStorage.getItem("aceitouCookies") == "1"
        ) {
          this.showBanner = false;
        } else {
          this.showBanner = true;
        }
      },
    },
    created() {},
    mounted() {
      this.checkCookies();

      function detectIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
        }

        var trident = ua.indexOf("Trident/");
        if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf("rv:");
          return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
        }

        var edge = ua.indexOf("Edge/");
        if (edge > 0) {
          // Edge (IE 12+) => return version number
          return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
        }

        // other browser
        return false;
      }

      if (detectIE() != false) {
        document.getElementById("alert-browser").style.display = "block";
      } else {
        console.log("safe to go");
      }

      setInterval(() => {
        // if (window.location.href.includes("/categoria/19")) {
        //   document.location = "/produto/56";
        // }
        if (window.location.pathname == "/categoria/19") {
          document.location = "/produto/56";
        }
        if (window.location.pathname == "/categoria/24") {
          document.location = "/produto/57";
        }
        if (window.location.pathname == "/categoria/8") {
          document.location = "/produto/13";
        }
        if (window.location.pathname == "/categoria/9") {
          document.location = "/produto/12";
        }
        if (window.location.pathname == "/familia/4") {
          document.location = "/produto/18";
        }
        if (window.location.pathname == "/categoria/1") {
          document.location = "/produto/1";
        }
        if (window.location.pathname == "/categoria/2") {
          document.location = "/produto/2";
        }
        if (window.location.pathname == "/categoria/5") {
          document.location = "/produto/5";
        }
      }, 200);
    },
  };
</script>
<style>
  .count-cart-items {
    display: inline-block;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    height: 15px;
    width: 15px;
    position: absolute;
  }
  .count-cart-items {
    top: 0;
    right: 0;
  }

  .menu-swiper-button-next:hover,
  .nav-swiper-button-next:hover,
  .nav-swiper-button-prev:hover,
  .menu-swiper-button-prev:hover {
    cursor: pointer;
  }

  .loadingLayer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    font-size: 3vh;
    text-align: center;
    color: #555;
    transition: 0.2s;
  }

  #alert-browser {
    display: none;
    position: absolute;
    left: 5%;
    bottom: 5%;
    background: rgba(234, 136, 34, 0.8);
    color: #fff;
    width: 90%;
    font-size: 16px;
    height: fit-content;
    padding: 15px;
    z-index: 1;
  }

  #alert-browser p {
    color: #fff;
    margin: 0;
  }

  #alert-browser span {
    float: right;
    margin-top: -33px;
    padding: 5px 20px;
    opacity: 0.95;
    background: rgba(255, 255, 255, 0.33);
  }
  #alert-browser span:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.53);
  }

  body {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  body::-webkit-scrollbar {
    width: 0 !important;
  }
  #user-area {
    left: 50vw;
    width: 50vw;
  }
  #user-area-inner-form {
    width: 50vw;
    left: 0px;
    height: calc(100vh - 45px);
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
  }
  #user-area #user-area-inner-form #user-area-close {
    margin-left: auto;
    color: #ffffff;
    padding: 15px;
    height: 15px;
    z-index: 999;
    font-size: 20px;
  }
  #user-area-close:hover {
    cursor: pointer;
  }
  #form-inner {
    color: #ffffff;
    padding: 15px;
    height: fit-content;
    width: 100%;
    display: flex;
    align-self: center;
    flex-direction: column;
    font-family: "Calibri", sans-serif;
    letter-spacing: 0.5px;
    font-size: 16px;
  }
  .diagonal-form {
    padding: 0.8rem 2rem;
    font-size: 16px;
    font-weight: 600;
    color: #000000 !important;
    display: inline-block;
    position: relative;
    z-index: 1;
    text-decoration: none;
    border: none;
    width: fit-content;
  }
  .diagonal-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transform: skew(-15deg);
    -webkit-transform: skew(-15deg);
    display: inline-block;
    z-index: -1;
  }
  #form-fields {
    color: #ffffff;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .row {
    display: flex;
    align-self: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .row div {
    align-self: flex-start;
    min-width: 30%;
  }
  .field {
    min-width: 45% !important;
  }
  .field i {
    margin-right: 10px;
    min-width: 20px;
  }
  .row input {
    display: inline-block;
    width: 40% !important;
    border: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ffffff;
    color: #000;
    padding: 2px 0 !important;
    font-family: "Calibri", sans-serif;
    font-size: 15px;
    font-weight: 600 !important;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .row input::placeholder {
    color: #545454;
    font-weight: 600;
  }
  /* #botao-actualizar {
      margin-left: 70%;
      width: fit-content;
      margin-top: 30px;
    }
    #botao-actualizar:hover {
      cursor: pointer;
      font-weight: bold;
    } */
  .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
  }
  .seccao.header-fixo main .main-inner-wrapper .main-coluna-conteudo {
    align-items: start !important;
  }
  #icon-user-header {
    margin-left: 30px !important;
    font-size: 2.8vh !important;
    margin-right: -20px !important;
    display: block;
  }
  #icon-user-header:hover {
    cursor: pointer;
  }
  .footer-avancar:hover {
    cursor: pointer;
  }

  .fade-enter-active {
    transition-property: opacity;
    transition-duration: 0.25s;
  }

  .fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.1s;
  }

  .fade-enter-active {
    transition-delay: 0.2s;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .logotipo-footer img {
    max-width: 70px !important;
  }

  .swiper-wrapper {
    height: fit-content;
  }
  .produtos-wrapper .produto-item {
    margin-top: 25px;
    max-width: calc(130vh / 3.5);
  }

  .produtos-wrapper.outros-produtos-wrapper
    .produto-item
    .imagem-wrapper:before {
    left: 0% !important;
  }
  .produtos-wrapper.outros-produtos-wrapper
    .produto-item
    .imagem-wrapper:after {
    right: -15% !important;
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    section main.main-reviews,
    .seccao main.contactos {
      max-height: calc(100vh - 128px - 18.35vh) !important;
    }
    .seccao.header-fixo:not(#second) main:not(.listagem-produtos-com-footer) {
      padding-top: calc(128px + 6.35vh);
    }
    .seccao.header-fixo:not(#second)
      main.smaller:not(.listagem-produtos-com-footer) {
      padding-top: calc(128px + 7.35vh) !important;
      height: calc(100vh - 170px - 23vh) !important;
    }
    nav .nav-swiper-button-next,
    nav .nav-swiper-button-prev {
      top: calc(94px + 6.75vh) !important;
    }
    .altura-auto {
      height: calc(100vh - 128px - 6.35vh) !important;
    }
    .seccao .nav-fixed nav {
      max-width: calc(100vw - 200px) !important;
      padding-left: 0px !important;
    }

    .menu {
      padding-left: 0px !important;
      text-align: center;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
    background-color: #999;
    opacity: 1;
  }

  .modalCookies {
    width: 65% !important;
    max-width: 65% !important;
    height: 62vh;
    overflow-x: hidden !important;
    padding: 0 !important;
    overflow-y: hidden !important;
  }
  .modalCookies .mt-3 {
    margin-top: 10px;
  }
  .modal {
    position: fixed;
    width: 13%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 99;
    max-width: 400px;
    background-color: #fff;
    padding: 25px;
  }
  #popup {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }

  #popup-paragraph {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #show-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    display: inline-block;
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 700;
    -webkit-box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
    box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
  }
  .close-popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    color: #000;
    cursor: pointer;
  }

  @media only screen and (max-width: 1023px) {
    .seccao main .main-inner-wrapper .main-coluna-imagens:before {
      display: none;
    }
    .slider-swiper-button-next {
      display: none !important;
    }
    section.seccao-produto
      main
      .main-inner-wrapper
      .main-coluna-imagens
      .buttons-wrapper
      .button:after {
      display: none !important;
    }
    .seccao.header-fixo:not(#second) main:not(.listagem-produtos-com-footer) {
      padding-top: calc(95px + 95px) !important;
    }
    .seccao main .main-inner-wrapper .main-coluna-conteudo h2 img {
      max-width: 25px !important;
      margin-bottom: -5px;
      margin-left: 10px;
    }
    .seccao .nav-fixed nav {
      padding: 10px;
      padding-top: 10px;
      height: auto;
    }
    .seccao.header-fixo .nav-fixed {
      top: 117px !important;
      padding-left: 30px;
      padding-right: 30px;
    }
    nav .nav-swiper-button-next,
    nav .nav-swiper-button-prev {
      top: 145px !important;
    }
    .footer-avancar.big.relative {
      z-index: 10;
    }
    .destaque-wrapper .destaque-inner-wrapper:before {
      display: none;
    }
    .destaque-wrapper .destaque-inner-wrapper .destaque-item {
      width: calc(100% - 30px) !important;
    }
    .destaque-wrapper .destaque-inner-wrapper:after {
      display: none;
    }
    .seccao.header-fixo .nav-fixed nav .menu .menu-item {
      margin-left: 25px !important;
    }
    #seccao-entrada footer .menu {
      padding-left: 0px !important;
      text-align: center;
    }
  }

  /* MODAL TABS */
  .modalCookies .col-8 {
    border-left: 1px solid #ccc;
    padding: 50px;
    padding-top: 20px;
  }
  .pen-heading {
    font-weight: bold;
    font-size: 4em;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
  }

  .modalCookies .tab-item {
    background: white;
    border-left: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 15px;
  }

  .modalCookies .tab-item:hover,
  .modalCookies .tab-item.active {
    box-shadow: 0;
    border-left: none;
  }

  .modalCookies .tab-item__heading {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #c9c9c9;
    margin: 0px;
  }
  .modalCookies .switchLabel {
    font-size: 12px;
    padding-left: 15px;
  }

  .modalCookies .active .tab-item__heading {
    color: #30b58e;
  }

  .modalCookies .tab-item__subheading {
    font-size: 18px;
    color: #333;
    margin: 0px;
  }
  .modalCookies li span {
    margin-top: 15px;
  }
  .modalCookies .tab-content__header {
    color: #314f8d;
    font-weight: bold;
    margin: 0px 0px 30px;
    font-size: 36px;
    line-height: 1.3em;
    letter-spacing: 0.02em;
  }

  .modalCookies .tab-content__text {
    margin: 0px 0px 30px;
    font-size: 1.25em;
  }

  .modalCookies .tab-content__btn {
    display: inline-block;
    margin-bottom: 30px;
    padding: 16px 50px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    position: relative;
    transition: all 0.3s ease;
    text-align: center;
    line-height: 1;
    border: 2px solid;
    border-radius: 3px;
    background-color: transparent;
    box-shadow: 0 2px 3px rgba(213, 218, 223, 0.35);
    color: #30b58e;
    fill: #30b58e;
    border-color: #30b58e;
  }

  .modalCookies .tab-content__btn:hover {
    color: #30b58e;
    text-decoration: none;
    box-shadow: 0px 3px 3px 2px rgba(213, 218, 223, 0.35);
  }

  .modalCookies .tab-content__testimonial {
    margin-bottom: 15px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.75);
    font-style: italic;
  }

  .modalCookies .tab-content__testimonial-author {
    margin-bottom: 5px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.75);
    font-weight: bold;
  }
  #listaCookies li,
  .modalCookies p {
    margin-top: 15px;
    font-size: 14px;
  }
  .button-wrapper.text-right {
    float: right;
  }
  .bannerCookies {
    position: fixed;
    bottom: 0;
    width: 100vw;
    right: auto;
    left: auto;
    background: white;
    z-index: 99999;
    display: flex;
    opacity: 0.96;
    padding-bottom: 20px;
    display: flex; /* primary flex container */
    flex-direction: row; /* horizontal alignment of flex items
                                      (default value; can be omitted) */
    align-items: stretch;
  }
  .bannerCookies p {
    font-size: 14px;
    text-align: justify;
  }
  .bannerCookies a {
    color: #30b58e;
  }
  .pointer {
    cursor: pointer !important;
  }
  .tab-item__subheading {
    width: 15px;
    background: #ccc;
    height: 3px;
    margin-top: 5px !important;
  }
  .active .tab-item__subheading {
    height: 3px;
    margin-top: 5px !important;
    width: 15px;
    background: #30b58e;
  }
  .bannerCookies .diagonal {
    font-size: 14px !important;
  }
  #popup {
    z-index: 9999999999999;
  }
  #submitCookies,
  #submitCookies1,
  #closeModal {
    margin-right: 50px;
    margin-top: 1vh;
    font-size: 14px;
  }
  .p-switch {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .bannerCookies .col-8 {
    width: 60%;
    padding: 20px;
    padding-bottom: 0;
  }
  .bannerCookies .col-4 {
    width: 40%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalCookies .col-8 {
    width: 80%;
    padding: 20px;
  }
  .modalCookies .col-4 {
    width: 20%;
    padding: 20px;
  }

  .modalCookies .container {
    display: flex;
  }
  /* .pretty.p-switch .state label::after,
	.pretty.p-switch .state label::before {
		top: -7px !important;
	} */
  .pretty.p-switch .state::before {
    height: 1.8vh !important;
    top: 0 !important;
  }
  .pretty.p-switch input:checked ~ .state label:after,
  .pretty.p-switch input:checked ~ .state label:before {
    left: 0.7em !important;
  }
  .bannerCookies .col-4 .col {
    width: 100%;
    padding-top: 10px;
  }
  .bannerCookies .col-4 {
    display: flex;
    text-align: center;
    padding: 0 50px;
  }

  .col.aceitarBanner {
    text-align: right !important;
    padding-right: 15px !important;
  }
  .col.naoAceitarbanner {
    text-align: left !important;
    padding-left: 15px !important;
  }
  .col.aceitarBanner a {
    width: 25%;
    min-width: 100px;
    text-align: center;
  }
  .col.naoAceitarbanner a {
    width: 25%;
    min-width: 100px;
    text-align: center;
  }
  .w3-display-topright {
    position: absolute;
    right: 3%;
    top: 10px;
    font-size: 25px;
    cursor: pointer;
  }

  .col-6 {
    width: 50% !important;
  }
  .col-12 {
    width: 100% !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  .row {
    min-width: 100% !important;
    width: 100% !important;
    /* display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; */
  }
  .text-left {
    text-align: left !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-right {
    text-align: right !important;
  }
  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }

  @media only screen and (max-width: 1000px) {
    .bannerCookies {
      display: block !important;
    }
    .bannerCookies .col-8,
    .modalCookies .col-8 {
      width: 100% !important;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
    .bannerCookies p,
    .modalCookies p {
      width: 90%;
    }

    .bannerCookies .col-4,
    .modalCookies .col-4 {
      width: 100% !important;
    }

    .modalCookies {
      position: fixed;
      top: 0;
      left: 0;
      max-width: 100% !important;
      width: 100% !important;
      height: 100vh !important;
      overflow-x: hidden !important;
      padding: 0 !important;
      overflow-y: hidden !important;
      transform: none !important;
    }

    #listaCookies {
      padding-left: 10px;
    }
    .col.aceitarBanner {
      text-align: right !important;
      padding-right: 15px !important;
    }
    .col.naoAceitarbanner {
      text-align: right !important;
      padding-left: 15px !important;
    }
    .col.aceitarBanner {
      text-align: center !important;
      padding-right: 15px !important;
      height: 50px;
    }
    .col.naoAceitarbanner {
      text-align: center !important;
      padding-left: 15px !important;
    }
    .col.aceitarBanner,
    .col.naoAceitarbanner,
    .bannerCookies .col-4 {
      padding-top: 0 !important;
      padding-left: 0 !important;
    }
  }

  .text-center {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    border-top: 1px solid #d9d9d9;
    text-align: center !important;
  }
  @import "https://cdn.jsdelivr.net/npm/pretty-checkbox@3.0/dist/pretty-checkbox.min.css";
</style>
