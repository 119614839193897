import Vue from 'vue'
import Vuex from 'vuex'

import api_calls from "@/api/api_calls"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    banners: [],
  },
   
  mutations: {
    UPDATE_STATE(state,payload) {
      state[payload.item] = payload.data;
    },
  },
  actions: {
    async collectBanners({commit}) {
      let method = "banners/listAll"
      let res = await api_calls.call_api_get(method)

      let banners = Object.values(res.banners);

      commit("UPDATE_STATE", {
        item: "banners",
        data: banners
      });
    },
  },
  modules: {
  }
})
